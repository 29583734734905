.modules {
  color: #2e455d;
  margin-top: 30px;
}

.mdHeading {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto; 
  display: block;
  padding: 24px 24px;
  background-color: #ccd4da;
  background-image: url(../../Img/EquipBG.jpg);
  background-size: 600px 600px;
}

.mdHDiv {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  max-width: 1024px;
  padding: 0 10px;
}

.mdHTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 54px;
  /* font-weight: lighter; */
  text-align: left;
}

.mdHMotto {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-top: 20px;
}

.mdMods {
  width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 24px 24px 0 24px;
    max-width: 1024px;
}

.modCage {
  width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 24px 16px;
    max-width: 1024px;
  max-height: 150px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 24px 0px;
  border: 1px solid rgb(226, 225, 230);
  border-radius: 8px;
}

.modTitle {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.modNum {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.mdList {
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 24px 2px;
    max-width: 1024px;
}

.mdLCage {
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 24px 16px;
    max-width: 1024px;
    max-height: 150px;
    border-bottom: 1px solid rgb(226, 225, 230);
    margin-top: -25px;
    margin-bottom: -25px;
}

.mdLCage > h5 {
  display: inline-block;
  margin: 0 10px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: #2e455d;
}

/* .mdLCage:hover {
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding: 24px 16px;
  max-width: 1024px;
  max-height: 150px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 24px 0px;
  border: 1px solid rgb(226, 225, 230);
  border-radius: 8px;
} */

/*Collapsible*/
.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  /* box-shadow: 1px 1px 4px rgba(0,0,0,0.2); */
}

.ctCage {
  display: flex;
  justify-content: space-between;
}

.ctCage > i {
  margin-top: 10px;
}


/* BEST - JS calculated height */
.list-group-item {
  .card-title {
    cursor: pointer;
  }
  .card-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;
  }

  h2 {
    margin-top: 5px !important;
    font-size: 26px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  p {
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  i {
    float: right;
  }
}

@media screen and (max-width: 767px) {
  .list-group-item {
    h2 {
      font-size: 20px;
    }
  }

  .mdList {
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 24px 1px;
    max-width: 1024px;
}

  .mdLCage {
    margin-left: auto;
   box-sizing: border-box;
   margin-right: auto;
   display: block;
   padding: 24px 1px;
   max-width: 1024px;
   max-height: 150px;
   border-bottom: 1px solid rgb(226, 225, 230);
   margin-top: -25px;
   margin-bottom: -25px;
}

  .mdLCage > h5 {
  display: inline-block;
  margin: 0 10px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: #2e455d;
  }
}

