.HomePage {
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 60px; */
}

h1, h2, h3 {
  text-align: center;
  font-weight: bold;
  font-family: 'Martel', serif;
}

.HomePageMe, .HomePageProjects, .HomePageContact{
  display: flex;
  justify-content: center;
  font-family: 'Hind', sans-serif;
  font-style: normal;
  background-color: transparent;
  /* margin-bottom: 30px; */
}

/* ADD */
.fileDiv {
  width: 500px;
  height: auto;
  margin: 50px auto 10px auto;
  /* border: 1px solid #eee; */
  border-radius: 20px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  background-color: white;
}

.devoOver {
  padding-top: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.knot{
border-top: 1px solid #0e2c3b;
width: 80%;
margin: 0 auto;
}


.fileDiv2 {
  text-align: center;
}

.titleF {
  font-size: 35px;
  padding: 30px;
}

.verF {
  padding: 0 50px;
}

.refF {
  text-align: right;
  margin-right: 50px;
  padding-bottom: 20px;
}

.devoF {
  padding: 0 50px;
  text-align: left;
}

.dial {
  margin: 0 auto;
  align-items: center;
}

.dialPhoto {
  width: 250px;
  display: block;
  margin: 0 auto;
  padding: 10px 0;
}

/* CLOSEOUT */

.fileImage {
  display: block;
  margin: 0 auto;
  width: 50%;
  height: auto;
  min-width: 300px;
  border-radius: 10%;
  box-shadow: 0 12px 30px 0 #eee;
}

.fileBlock {
  display: inline;
  margin: 5px;
}

.fileContent {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 10px; */
}


.HomePageProjects {
  margin-top: 30px;
  margin-bottom: 60px;
}

.HomePageEducation {
  /* padding-bottom: 30px; */
  width: 100%;
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  justify-content: center;
  font-family: 'Hind', sans-serif;
  font-style: normal;
  /* background-color: transparent;
  margin-bottom: 30px; */
}

.HPEducation__content {
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  /* margin-left: 20px;
  margin-right: 20px; */
  padding: 30px 0;
}

.HomePageContact {
  margin-bottom: 30px;
  height: 170px;
}

.HPTitle {
  margin: 100px 0 70px 0;
}

.HPMimg {
  width: 500px;
  display: block;
  margin: 0 auto;
}

.HPWelcome {
  /* background-color: #1d4062; */
  padding: 30px 0 100px 0;
  color: #1d4062;
}

.HPTitle > h1 {
  font-family: 'Hind', sans-serif;
  text-align: center;
}

.HPTitle > h2 {
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0.02em;
}

.HPTitleg > h2 {
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  font-style: normal;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0.02em;
  color: #1d4062;
  padding-bottom: 30px;
}


.line {
width: 50%;
/* height: 7px; */
border-bottom: 1px solid #1d4062;
margin: 20px auto;
/* position: absolute; */
}

.HPWI {
  margin: 40px auto;

}

.HPWP {
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 400;
  /* font-size: 1.9em; */
  line-height: 1.7em;
  letter-spacing: 0.03em;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
  width: 70%;
}

.HPAccess {
  padding: 1px 0 50px 0;
  background-color: #fafafa;
}

.HPGospel {
  padding: 40px 0 80px 0;
  /* background-color: #ccd4da; */
}

.HPG {
  margin: 0 auto;
  width: 700px;
  background-color: #ccd4da;
  padding: 20px;
  border-radius: 90px;
  /* box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .1); */
}

.HPGsec {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px;
  /* background-color: #ccd4da; */
  margin: 20px 0;
  /* border-radius: 90px; */
}

.HPGimg {
  width: 100px;
  margin-right: 50px;
  background-color: white;
  border-radius: 50%;
}


.HPGit {
  /* width: 30%; */
  color: #1d4062;
  font-family: Helvetica, sans-serif;
  font-weight: bolder;
  font-size: 30px;
}

.bigger {
  font-size: 40px;
  color: #fafafa;
  text-shadow: -1px -1px 0 #1d4062, 1px -1px 0 #1d4062, -1px 1px 0 #1d4062, 1px 1px 0 #1d4062;
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;

}

.ECc {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}


.ECcP {
  margin-left:10px;
  margin-right: 10px;
  width: 250px;
  height: 200px;
  text-align: center;

}

.Ec1 {
  background-color: #D18266;
  padding: 60px 0;
}

.Ec2 {
  background-color: #D5E3E6;
  padding: 40px 0;
}

.Ec3 {
  background-color: #F0E2D2;
  padding: 50px 0;
}

.EPa {
  margin: 0 10px;
  text-align: center;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: -.75rem;
  color: #EFEEE5;
}

.EPb {
  margin: 0 10px;
  text-align: center;
  font-weight: 600;
  font-family: "Helvetica", sans-serif;
  font-size: 60px;
  line-height: 0.9;
  color: #D18266;
}

.EPc {
  margin: 0 10px;
  text-align: center;
  font-weight: 600;
  font-family: "Kaushan Script", cursive;
  font-size: 60px;
  font-weight: 900;
  color: #333333;
}


.EPa:hover{
  text-decoration: none;
  color: #1d4062;
}

.EPb:hover {
  text-decoration: none;
  color: #333333;
}

.EPc:hover {
  text-decoration: none;
  color:#758694
}


/* .ECcProfile {
  width: 340px;
  height: 240px;
  margin: 0 10px;
}

.ECcProfile:hover {
  text-decoration: none;
}

.ECcProfile > h3{
  text-align: center;
  font-weight: 600;
} */

/* .ep1 {
  background-color: #D18266;
} */

/* .ep2 {
  background-color: #D5E3E6;
} */

/* .ep3 {
  background-color: #F0E2D2;
} */

/* .ep1 > h3 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 75px;
  font-weight: 900;
  letter-spacing: -.75rem;
  color: #EFEEE5;
  padding-top: 20%;
} */

/* .ep2 > h3 {
  font-family: "Helvetica", sans-serif;
  font-size: 80px;
  line-height: 0.9;
  color: #D18266;
  padding-top: 5%;
}

.ep3 > h3 {
  font-family: "Kaushan Script", cursive;
  font-size: 75px;
  padding-top: 15%;
} */

.me_content, .HPProjects__content, .HPContact__content {
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  z-index: 1;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.HPProjects__content, .HPContact__content  {
  margin-top: 65px;
}

/* .HPProjects__content  > h2 {
  margin-top: 80px;
} */

.HPF1 {
  margin-top: 85px;
  height: 50px;
  width: 100%;
  background-color: #ced4d7;
}
.HPF2 {
  height: 50px;
  width: 100%;
  background-color: #7D8D9C;
}
.HPF3 {
  height: 50px;
  width: 100%;
  background-color: #637483;
  margin-bottom: -20px;
}

.HPF_content {
  margin: 20px 0;
}
.HPF_content {
  text-align: center;
}

.HP_button {
  text-align: center;
  font-weight: bold;
  font-family: 'Open Sans';
  font-size: 30px;
  color: #4D5A66;
  display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    background: transparent;
}

.HPProjects__content  > p, .HPEC > p {
  margin-top: 20px;
}

.HPEducation__content {
  color: #ced4d7;
  /* height: 1100px; */
    width: 100%;
    background-color: #4D5A66;
    ;
    /* position: absolute;
    z-index: 1; */
  margin-top:20px;
}

.degSect {
  width: 610px;
  margin: 200px auto 100px auto;
}

.meButton, .projectsButton {
  border: none;
  cursor: pointer;
  background-color: #ced4d7;
  border-radius: 10px;
  padding: 21px 34px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.meButtonTablet {
  display: none;
}

.mcbg {
  height: 450px;
  width: 280px;
  background-color: #FAFAFA;
  opacity: 0.25;
  border-radius: 15px;
  position: absolute;
  z-index: -1;
}

.HPMe__background {
  height: 1450px;
  width: 100%;
  /* background-color: #FAFAFA; */
  position: absolute;
  z-index: 1;
}

.HPEducation__background {
  height: 1100px;
  width: 100%;
  background-color: #4D5A66;;
  position: absolute;
  z-index: 1;
}

.HPProjects__background {
  width: 100%;
  left: 0;
  right: 0;
  height: 1000px;
  object-fit: cover;
  position: absolute;
  z-index: 0;
}

.HPContact__background {
  height: 200px;
  min-height: 200px;
  width: 100%;
  background-color: #eeede9;
  position: absolute;
  z-index: 1;
}

.homePageLink {
  color: black;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .2em;
  text-decoration: none;
}

.homePageLink:hover {
  color: black;
  text-decoration: none;
}


.personPhoto {
  width: 290px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  padding-top: 70px;
}

.jobalDesc {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  font-family: "Helvetica Neue","Verdana", "sans-serif", "New Century Schoolbook", "serif";
  letter-spacing: 2px;
  max-width: 700px;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;

}

ol {
  padding: 0;
  margin: 0; 
}

.HPCLink {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-bottom: 5px;
}

.HPCIcon {
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  color: #0e2c3b;
  margin: 10px;
  line-height: 28px;
  width: 26px;
  height: 26px;
}

.HPCIcon:hover {
  color: #F0F2F1;
  background-color: #0e2c3b;
  text-decoration: none;
  border-radius: 100%;
}

@media screen and (min-width: 768px) {
  .me_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 700px;
    padding-top: 50px;
  }

  .meInfo {
    margin-left: 40px;
  }

  .meButtonTablet {
    display: flex;
    border: none;
    cursor: pointer;
    background-color: #ced4d7;
    color: #000;
    border-radius: 10px;
    padding: 21px 34px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  .meButton {
    display: none;
  }
  
  .personPhoto {
    margin-top: 20px;
    width: 450px;
  }

  .AbIcon {
    text-align: center;
    text-decoration: none;
    font-size: 50px;
    color: #FAFAFA;
    margin: 30px 0 10px 0;
    line-height: 28px;
    width: 26px;
    height: 26px;
  }

  .AbT {
    text-align: center;
  }

  .HPContact__content {
    color: #0e2c3b;
  }

  .HPCIcon {
    text-decoration: none;
    font-size: 25px;
    line-height: 38px;
    width: 36px;
    height: 36px;
  }

  .suminfo {
    width: 400px;
  }

  .HPEC {
    margin-bottom: 20px;
  }

  .HPMe__background {
    height: 680px;
    width: 100%;
    background-color: #FAFAFA;
    position: absolute;
    z-index: 1;
    margin-top: 40px;
  }

  .HPProjects__content,  .HPContact__content {
    margin-top: 80px;
  }

  /* .HPProjects__content, .HPEC {
    width: 600px;
  } */

  .mcbg {
    height: 330px;
    width: 500px;
  }
  
  .HPProjects__content > h2 {
    margin-top: 30px;
  }

  .HPEducation__content >h2{
    padding-top: 10px;
  }

  .HomePageContact {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 200px;
    background-color: #f2f3f3;
  }

  .HPEducation__background {
    height: 1200px;
  }

  .HomePageContact {
    /* margin-bottom: 30px; */
  }
    
  .HPContact__content  {
    margin-top: 30px;
  }
    
}

@media screen and (max-width: 767px) {
 .fileDiv, .fileDiv2, .devoOver {
  border-radius: 0%;
 }
 .HomePageMe {
  margin-bottom: 30px;
 }

 .HPMe__background {
   height: 1140px;
 /* .HPF1, .HPF2, .HPF3 {
  display: none;
 } */
}
 .HPContact__content {
  margin-top: -20px;
 }

 .HPCLink {
  margin-top: 20px;
 }

.HPCIcon {
  text-align: center;
  text-decoration: none;
  font-size: 30px;
  color: #0e2c3b;
  margin: 10px;
  line-height: 48px;
  width: 46px;
}

/* NEW INSERT */
.HPMimg {
  width: 300px;
  display: block;
  margin: 0 auto;
}

.HPWelcome {
  /* background-color: #1d4062; */
  padding: 0 0 20px 0;
  margin-top: 60px;
  color: #1d4062;
}

.HPTitle > h1 {
  font-family: 'Hind', sans-serif;
  text-align: center;
}

.HPTitle > h2 {
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0.02em;
}

.HPTitleg > h2 {
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  font-style: normal;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0.02em;
  color: #1d4062;
  padding-bottom: 30px;
}


.HPWI {
  margin: 40px auto;
}

.HPWP {
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 400;
  /* font-size: 1.9em; */
  line-height: 1.7em;
  letter-spacing: 0.03em;
  margin: 0 auto;
  text-align: center;
  width: 70%;
  margin-bottom: 50px;
}


.HPGospel {
  padding: 40px 0 0 0;
  /* background-color: #ccd4da; */
}

.HPG {
  margin: 0 auto;
  width: 100%;
  background-color: #ccd4da;
  padding: 20px;
  border-radius: 0;
  /* box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .1); */
}

.HPGsec {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px;
  /* background-color: #ccd4da; */
  margin: 10px 0;
  /* border-radius: 90px; */
}

.HPGimg {
  width: 60px;
  margin-right: 20px;
  background-color: white;
  border-radius: 50%;
}

.HPGit {
  /* width: 30%; */
  color: #1d4062;
  font-family: Helvetica, sans-serif;
  font-weight: bolder;
  font-size: 20px;
}

.bigger {
  font-size: 25px;
  color: #fafafa;
  text-shadow: -1px -1px 0 #1d4062, 1px -1px 0 #1d4062, -1px 1px 0 #1d4062, 1px 1px 0 #1d4062;
  font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.HPAccess {
  /* margin-top: 40px; */
  margin-top: -10px;
}

/*OLD*/

.ECc {
  display: block;
}


.ECcP {
  width: 240px;
  height: 170px;
  margin: 20px auto 0 auto;
}

.Ec1 {
  background-color: #D18266;
  padding: 60px 0;
}

.Ec2 {
  background-color: #D5E3E6;
  padding: 40px 0;
}

.Ec3 {
  background-color: #F0E2D2;
  padding: 50px 0;
}

.EPa {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  font-size: 55px;
  font-weight: 900;
  letter-spacing: -.75rem;
  color: #EFEEE5;
  padding: 45px 0x;
}

.EPb {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-family: "Helvetica", sans-serif;
  font-size: 55px;
  line-height: 0.9;
  color: #D18266;
  padding: 45px 0;
}


.EPc {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-family: "Kaushan Script", cursive;
  font-size: 55px;
  font-weight: 900;
  color: #333333;
  padding: 45px 43px;
}

/*OLD*/




.ECcProfile {
  width: 240px;
  height: 140px;
  margin: 0 auto;
}

.ECcProfile:hover {
  text-decoration: none;
}


/* .ep1 > h3 {
  font-size: 55px;
  letter-spacing: -.75rem;
  padding-top: 20%;
} */

.ep2 > h3 {
  font-family: "Helvetica", sans-serif;
  font-size: 60px;
  line-height: 0.9;
  color: #D18266;
  padding-top: 5%;
}

.ep3 > h3 {
  font-family: "Kaushan Script", cursive;
  font-size: 65px;
  padding-top: 15%;
}

.degSect {
  width: 300px;
  margin: 10px auto 150px auto;
}
}