html {
  font-size: 62.5%;
  /* 16px x 62.5 = 10px = 1 rem */
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  font-size: 1.6rem;
  font-family: Open Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #fafafa; */
}

h1, h2, h3 {
  font-family: Open Sans;
}

/* This connects to public index.html */
#root {
  height: 100%;
}

/* Layout */
.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 4rem 1fr 5rem;
  height: 100%;
}

header {
  grid-area: header;
  padding-top: 5px;
  padding-bottom: 95px;
  background-color: #f2f3f3;
  font-family: Open Sans;
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  min-width: 100%;
  /* border-bottom-width: 1px;
  box-shadow: 0 5px 6px -1px rgba(0, 0, 0, .1),
    0 2px 4px -1px rgba(0, 0, 0, .06); */
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
  background-color: #2e455d;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 12px;
  color: white;
  text-align: center;
  font-family: Open Sans;
  z-index: 3;
}

footer > p {
  margin-bottom: 1px;
}

.logo {
  width: 90px;
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translate(-50%); */
  padding-top: 5px;
  margin-left: 100px;
  display: inline-block;
  text-align: center;
}


.navFlex {
  display: flex;
  width: 200px;
}

.navFlex > h2 {
  color: #364042;
  margin-left: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding-top: 10px;
}

.navFlex:hover {
  text-decoration: none;
}

.navFlex > h2:hover {
  text-decoration: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.row.center {
  justify-content: center;
}

.row1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
}

.row.top {
  align-items: flex-start;
}

.row.top.alpha {
  margin-top: 80px;
}

.construction {
  text-align: center;
  font-size: 40px;
}

/* .list {
  padding-inline-start: 0;
} */

/* SUBMIT ADDRESS */
.SubmissionAddress {
  /* margin-top: 10px; */
  /* margin-bottom: 130px; */
  margin-left: auto;
  margin-right: auto;
  width: 350px;
  /* border-radius: 10px;
  border: 4px solid #eeede9; */
  /* padding-top: 60px;
  padding-bottom: 40px; */
}

/* .SubmissionAddressHeader {
  font-family: 'Baloo Chettan 2', cursive;
} */

.submissionAddress__form {
  /* padding-left: 5%;
  padding-right: 5%; */
  /* font-family: 'Roboto', sans-serif; */
  font-size: 16px;
  /* padding-bottom: 30px; */
  padding: 30px 5% 30px 5%;
}

.submissionFormInfo {
  text-align: left;
  margin-bottom: 10px;
}

.SubmissionTitle {
  text-decoration: underline;
}


.textInfo {
  width: 238px;
}

.textInfo2 {
  width: 185px;
}

.numbInfo {
  width: 30px;
}

.emailInfo {
  width: 238px;
  margin-left: 15px;
}

.notesInfo {
  width: 235px;
  margin-left: 15px;
}

.AddressSubmit__buttons, .gbButton {
  text-align: center;
}

.gbButton, .SubmissionAddressHeader {
  padding-top: 20px;
}

/* ADDRESSES */
.listsTeams {
  /* padding-top: 60px; */
  /* margin-top: 150px; */
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  /* border-radius: 10px;
  border: 4px solid #eeede9;
  padding-bottom: 40px; */
  align-items: center;
}

.gbButton {
  padding-top: 15px;
}

.GoBack__button {
  border: 0;
  color: white;
  font-size: 18px;
  position: relative;
  /* font-family: 'Baloo Chettan 2', cursive; */
  background-color: #0e2c3b;
  border-radius: 7px;
}

.listTitle {
  color: #0e2c3b;
  text-decoration: underline;
  font-size: 28px;
}

.addrSecLink {
  /* font-family: 'Roboto', sans-serif; */
  font-size: 20px;
  padding-left: 18%;
  padding-right: 15%;
  text-decoration: none;
  color: #0e2c3b;}

.addrSecLinkH3 {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 40px;
  margin-right: 10px;
  margin-bottom: -60px;
  border: 2px solid #8D98A1;
  font-size: 17px;
}

.addrSecLinkH3:hover {
  background-color: #A4B1cA;
  margin-left: 40px;
  margin-right: 10px;
  border: none;
}

.AddAddress__button {
  text-align: center;
  margin-bottom: 30px;
}

.addrSecLinkorderList {
  clear: both;
  list-style: none;
  padding-left: 4%;
}

.addrSecLinkorderList li {
  /* margin: 2em 0; */
  position: relative;
  counter-increment: inst;
}

.addrSecLinkorderList li::before {
  content: counter(inst);
  background: #0e2c3b;
  color: #fff;
  font-weight: 700;
  font-style: italic;
  /* text-shadow: 1px 1px rgba(255, 150, 0, 0.5); */
  border-radius: 0 0.675em 0.675em 0;
  font-size: 1.25em;
  text-align: center;
  padding-top: 0;
  padding-left: 0;
  left: -5%;
  top: 2.5em;
  height: 1.35em;
  width: 1.75em;
  position: absolute;
  transition: all 0.2s ease-in-out;
  z-index: -1;
}

.AddAddresses {
  background-color: #0e2c3b;
  color: white;
  padding: 5px;
  margin-top: 40px;
  border-radius: 7px;
  border: none;
}

.AddAddressesLink {
  text-decoration: none;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: bold;
  font-size: 16px;
  color: white;
}

/* TEAMS */
.listCage {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 300px;

  margin: 10px auto;
}
.list {
  list-style: none;
  border: 1px solid #899daa;
  border-radius: 10px;
  width: 100px;
  margin: 10px auto;
}
.listLink {
  text-decoration: none;
}
.listLink:active {
  text-decoration: none;
}
.listLinkTx {
  font-size: 20px;
  color: #314a6b;
}
.listLinkTx:active {
  color: #899daa;
}

@media screen and (max-width: 425px) {
  .listsTeams {
    max-width: 380px;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    width: 70px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding-top: 15px;
    margin: 0 auto;
    
  }
  
  .navFlex {
    display: block;
    align-items: center;
  }
  
  .navFlex > h2 {
    display: none;

  }
}


@media screen and (min-width: 1230px) {
  .listsTeams {
    max-width: 800px;
  }

  .addrSecLinkorderList {
    padding-left: 4.5%;
  }

  .addrSecLinkH3 {
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 50px;
    margin-right: 30px;
    margin-bottom: -60px;
    border: 2px solid #8D98A1;
  }

  .addrSecLinkH3:hover {
    background-color: #A4B1cA;
    margin-left: 50px;
    margin-right: 30px;
    border: none;
  }
}

@media screen and (min-width: 1230px) {
  .SubmissionAddress {
    max-width: 450px;
    margin-top: 210px;
    margin-bottom: 320px;

  }

  .textInfo {
    width: 295px;
  }

  .emailInfo {
    width: 295px;
    margin-left: 17px;
  }

  .notesInfo {
    width: 295px;
    margin-left: 17px;
  }

}